const About = ({ name }) => {
    //const name = 'Ming'

    return (
        <div>
            <h1>About</h1>
        </div>
    )
}

export default About
