import './App.css'

import About from './pages/about/About'

function App() {
    return (
        <div className="App">
            <About name={'Ming'} />
        </div>
    )
}

export default App
